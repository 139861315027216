import React from 'react';

import Page from '../components/core/page';

export default () => (
	<Page title="404">
		<h1>NOT FOUND</h1>
		<p>This page does not exist.</p>
	</Page>
);
